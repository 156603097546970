<script setup>
import { ref, onMounted } from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import { saveGridState } from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import pageTitle, { setPageTitle } from "../../../utils/pageTitle";
import {
  connectToServerApi
} from "@/utils/agGridUtils";
import EditDeleteRenderer from "../../../components/common/aggrid/EditDeleteRenderer.vue";
import { useRouter } from 'vue-router';

const themeStore = useThemeStore();
let gridApi;
const GRID_SAVE_KEY = "bins";
const BILLING_RATES_EXTERNAL_FILTERS_KEY = "billing-rates-external-filter";
const BILLING_RATES_URL = "/rates";

const advancedFilterKeys = ["id", "category", "code", "description", "cost", "our_cost", "qty"];

const route = useRouter();

const context = ref(null);

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl:BILLING_RATES_URL,
    filterKey: BILLING_RATES_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys:advancedFilterKeys
  };
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

onMounted(() => {
  setPageTitle("Default Billing Rates");
});

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const gridOptions = {
  rowModelType: "serverSide",
  rowSelection: "multiple",
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
};

const colDefs = ref([
  {
    field: "id",
    headerName: "#",
    cellDataType: "number",
  },
  {
    field: "category",
    headerName: "Category",
    cellDataType: "text",
  },
  {
    field: "code",
    headerName: "Code",
    cellDataType: "text",
  },
  {
    field: "description",
    headerName: "Description",
    cellDataType: "text",
    width: 135,
  },
  {
    field: "cost",
    headerName: "Fee",
    cellDataType: "float",
  },
  {
    field: "our_cost",
    headerName: "Our Cost",
    cellDataType: "float",
  },
  {
    field: "qty",
    headerName: "Quantity In Stock",
    cellDataType: "float",
  },
  {
    field: "quickbook.name",
    headerName: "Quick Book Name",
    cellDataType: "float",
  },
  {
    headerName: "Actions",
    width: 50,
    filter:false,
    sortable:false,
    cellRenderer: EditDeleteRenderer,
    cellRendererParams: {
      editUrl: `/billings/default-billing-rates`,
      showEdit: true
    }
  }
]);

const rowDoubleClicked = (gridParams) => {
  route.push(`/billings/default-billing-rates/${gridParams.data.id}/view`);
}

</script>

<template>
  <div class="h-100 ml-4 mr-4">
    <v-card-title class="mb-4">{{ pageTitle }}</v-card-title>
    <AgGridVue class="h-100" :class="themeStore.agTheme" :columnDefs="colDefs" :gridOptions="gridOptions"
      :defaultColDef="defaultColDef" @grid-ready="onGridReady" @rowDoubleClicked="rowDoubleClicked" :context="context">
    </AgGridVue>
  </div>

</template>